import React from "react"

export default function Home(props) {
  return (
    <section>
      <div className="mt-5">
        <h3>Welcome to Above And Beyond Perspective</h3><br/>
        <p>Being a licensed drone pilot, I can fly my drone with almost no restrictions, and fly missions of various objectives.</p>
        <p>As a passionate photographer, I love to catch the image that creates unforgettable memories.</p>
        <p>It is important for me that others can project themselves into my images, as I see them and feel them, and therefore to translate my photographic sensibility by these timelessly moments captured.</p>
      </div>
      </section>
  )
}
