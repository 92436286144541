import React from "react"
import {Card} from 'react-bootstrap'
import { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"

const cardStyle = {
  backgroundColor: "transparent",
//   minWidth : "200px",
}

const cardStyleBig = {
    backgroundColor: "transparent",
    // width : "100%",
}

const bottomRight = {
    position: 'absolute',
    bottom: '-20px',
    right: '20px'
}
export default function ServiceDetails(props) {

    const [smallScreen, setSmallScreen] = useState(false)
    const aida3 = "../images/events.jpeg"
    const aida4 = "../images/wed_ring.jpg"
    const highlight2 = "../images/carousel/A1-highlight2.png"
    const highlight = "../images/carousel/A3-highlight1.png"
    return (
        <>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={highlight2}
                    alt="Highlight 2 terrain"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">Real Estate</Card.Title>
                <Card.Text>
      <p>Giving the best viewing experience, from above, giving the best visibility so that everyone can see their future land or house from every angle.</p>
<div class="video-container">
      <iframe src="https://www.youtube.com/embed/WD_gT1s3nTI?controls=0&loop=1&autoplay=1&mute=1&playlist=WD_gT1s3nTI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
        <br/>
        Drone pictures: 75&euro;<br/>
        Drone video with pictures and editing: 150&euro;<br/>
            </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
            </Card.Body>
            </Card>
            <br></br>
            </div>

          <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={highlight}
                    alt="Highlight of the terrain"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">Real Estate</Card.Title>
                <Card.Text>
      <p>Giving the best viewing experience, from above, giving the best visibility so that everyone can see their future land or house from every angle.</p>
<div class="video-container">
      <iframe src="https://www.youtube.com/embed/y2T9N-QaWfo?controls=0&loop=1&autoplay=1&mute=1&playlist=y2T9N-QaWfo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
        <br/>
        Drone pictures: 75&euro;<br/>
        Drone video with pictures and editing: 150&euro;<br/>
            </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
            </Card.Body>
            </Card>
            <br></br>
            </div>


                        <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={aida4}
                    alt="Aida 3 Course"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>

                <Card.Title className="text-light">Weddings</Card.Title>
                <Card.Text>
<p>Your wedding is a very special moment in your life, and I, through my photography, will catch the essence of each moment and capture all the emotions that will become eternal memories.</p>

       <p className="row" style={bottomRight}>Price :  upon contact</p>
                </Card.Text>

            </Card.Body>
            <Card.Body bg-transparent>
            </Card.Body>
            </Card>
            <br></br>
            </div>


      <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={smallScreen ? cardStyleBig : cardStyle}>
            <StaticImage
                    src={aida3}
                    alt="Aida 2 Course"
                    placeholder="blurred"
                    layout="constrained"
                    quality={100}
                />
            <Card.Body bg-transparent>
                 <Card.Title className="text-light" >Events</Card.Title>
                <Card.Text>
<p>From the sky or in the action, anything is possible. Discover new perspectives.</p>

       <p className="row" style={bottomRight}>Price :  upon contact</p>
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            </div>
            </>
  )
}

