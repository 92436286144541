import React from 'react'

const Details = () => (
  <>
    <div className='text-light'>
    <h3 className='pt-5'>Address</h3>
      <p>Above And Beyond Perspective<br></br>
        Portugal - Madeira, Calheta
      </p>
      <p><h3>Phone</h3> (+32) 497 638 393</p>
      <h3>Email</h3><a href='mailto:contact@aboveandbeyondperspective.com'>contact@aboveandbeyondperspective.com</a>
    </div>
  </>
)
export default Details
